exports.strings = {
    appName: "Flight",
    loginTitle: "Login to Flight",
    loginPreInviteButton: "Or ",
    loginRequestInvite: "request an invite.",
    organizationsOverviewTitle: "Your Organizations",
    homeGreeting: "Welcome back,",
    products: "Products",
    sellers: "Sellers",
    newProductModalTitle: "Create a New Product",
    newProductModalDescription: "Start by giving this product a name or codename.",
    newProductModalAction: "Create",
    recentProductsTitle: "Products",
    recentProductsSubtitle: "Recently edited products.",
    newOrganizationFormTitle: "Create a New Organization",
    newOrganizationFormDescription: "Start by giving this organization a name or code-name. Your organization URL cannot be changed.",
    newOrganizationFormAction: "Create",
    shareDialogTitle: 'Collaboration',
    shareDialogDescription: "Add collaborators and choose how you want to control access to this organization. Users you add here will have access to your organization's Fligth portal and all of its products.",
    product: {
        quickActions: {
            quickAction1Title: 'Positioning',
            quickAction1Description: "Edit your product's core value proposition, key benefits, and features.",
            quickAction2Title: 'Key Benefits',
            quickAction2Description: "See insights about the market for your product.",
            quickAction3Title: 'Market Trends',
            quickAction3Description: "Explore recommendations to optimize your sales content.",
            quickAction4Title: 'Company Facts',
            quickAction4Description: "Add customer references that can be used in sales content.",
        }
    },
    collaborators: {
        collaboratorsWidgetTitle: 'Collaborators',
        collaboratorsWidgetDescription: 'Add collaborators to work with others on positioning and sales content.',
    },
    playbooks: {
        title: 'Sales Playbooks',
        newPlaybookModalTitle: 'Create a New Sales Playbook',
        newPlaybookModalDescription: 'Start with the basics.',
        newPlaybookModalPlaceholder: 'Playbook Name',
        newPlaybookModalAction: 'Create',
        recentPlaybooksTitle: 'Recent Playbooks',
        newPlaybookModalNoPositioningTitle: 'Oh no!',
        newPlaybookModalNoPositioningHelper: 'It looks like you haven\'t created a positioning framework for this product yet. Please do so before creating a playbook.',
        newPlaybookModalNoPositioningAction: 'Create Positioning',
    },
    assets: {
        title: 'Sales Assets',
        newAssetModalNoPositioningTitle: 'Oh no!',
        newAssetModalNoPositioningHelper: 'It looks like you haven\'t created a positioning framework for this product yet. Please do so before creating a sales asset.',
        newAssetModalNoPositioningAction: 'Create Positioning',
    },
    authentication: {
        inviteCodeLabel: "",
        inviteCodePlaceholder: "Invide Code",
        emailLabel: "Email Address",
        emailPlaceholder: "",
        passwordLabel: "Choose Password",
        passwordPlaceholder: "",
        confirmPasswordLabel: "Confirm Password",
        confirmPasswordPlaceholder: "",
        firstNameLabel: "First Name",
        firstNamePlaceholder: "",
        lastNameLabel: "Last Name",
        lastNamePlaceholder: "",
        organizationSizeLabel: 'Organization Size',
        companyIndustryLabel: 'Industry',
        jobTitleLabel: 'Job Title',
    },
    positioning: {
        preTitle: '',
        title: 'Positioning',
        subTitle: '',
        introTitle: "Let's get started",
        introParagraph: "Before we can create any sales assets, we need to know how you talk about your product. Don't worry, we've made this really easy.",
        blankPositioningAction: 'Start Blank',
        blankPositioningDescription: 'Input your messaging using our best-in-class positioning framework.',
        quickStartPositioningAction: 'Quick Start',
        quickStartPositioningDescription: 'Use AI to create your positioning from a blogpost, messaging document, or press release.',
        forms: {
            productOverviewTitle: "Product Overview",
            productOverviewDescriptor: "Tell us the basics about your product. The more details you provide, the better for our AI co-pilot.",
            customerFacingProductNameLabel: "Customer facing product name",
            customerFacingProductNamePlaceholder: "Airbus A380",
            oneSentenceDescriptorLabel: "Once sentence product descriptor",
            oneSentenceDescriptorPlaceholder: "This is where we will have a really great example of a descriptor",
            audienceTypeLabel: "",
            audienceTypePlaceholder: "",
            quickStartOverviewTitle: "Quick Start Positioning",
            quickStartOverviewDescriptor: "We'll use AI to start a positioning framework from content you already have. Simply paste in a blog post, press release, messaging document, or any text that talks about your product.",
        }
    },
    notebook: {
        preTitle: '',
        title: 'Notebook',
        subTitle: '',
        introTitle: "Let's get started",
        introParagraph: "Before we can create any sales assets, we need to know how you talk about your product. Don't worry, we've made this really easy.",
        blankPositioningAction: 'Start Blank',
        blankPositioningDescription: 'Input your messaging using our best-in-class positioning framework.',
        quickStartPositioningAction: 'Quick Start',
        quickStartPositioningDescription: 'Use AI to create your positioning from a blogpost, messaging document, or press release.',
        forms: {
            quickStartModalTitle: "Quick Start",
            quickStartModalDescription: "Use AI to create your positioning from a blogpost, messaging document, or press release.",
            quickStartModalUploadAction: "Upload",
            quickStartModalPasteAction: "Copy and Paste",
            productOverviewTitle: "Product Overview",
            productOverviewDescriptor: "Tell us the basics about your product. The more details you provide, the better for our AI co-pilot.",
            customerFacingProductNameLabel: "Customer facing product name",
            customerFacingProductNamePlaceholder: "Airbus A380",
            oneSentenceDescriptorLabel: "Once sentence product descriptor",
            oneSentenceDescriptorPlaceholder: "This is where we will have a really great example of a descriptor",
            audienceTypeLabel: "",
            audienceTypePlaceholder: "",
            quickStartOverviewTitle: "Quick Start Positioning",
            quickStartOverviewDescriptor: "We'll use AI to start a positioning framework from content you already have. Simply paste in a blog post, press release, messaging document, or any text that talks about your product.",
        }
    },
    playbookEditor: {
        preTitle: '',
        title: 'Playbook',
        subTitle: '',
        addModuleButton: 'Add Section',
    }
}
import firebase from "../../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { handleError } from "./utils/handleError";
const functions = getFunctions();

// Waitlist Survey
export const addToBasicWaitlist = (data) => async (dispatch) => {
  const addToBaiscWaitlistFunction = httpsCallable(
    functions,
    "addToBasicWaitlist"
  );

  const createRequest = await addToBaiscWaitlistFunction(data);

  return createRequest.data;
};

// Waitlist Survey
export const addToWaitlist = (data) => async (dispatch) => {
  console.log(data);
  const addToWaitlistFunction = httpsCallable(functions, "addToWaitlist");
  const createRequest = await addToWaitlistFunction(data);
  return createRequest.data;
};

// Check if organization handle exists
export const checkOrganizationHandle = (data) => async (dispatch) => {
  const checkOrganizationHandleFunction = httpsCallable(
    functions,
    "checkOrganizationHandle"
  );
  const createRequest = await checkOrganizationHandleFunction(data);
  return createRequest.data;
};

// Create Organization TODO: Add success confirmation in the component
export const createOrganization = (data) => async (dispatch) => {
  const createOrganizationFunction = httpsCallable(
    functions,
    "createOrganization"
  );
  const createRequest = await createOrganizationFunction(data);
  if (createRequest.data.success) {
    dispatch({
      type: "UPDATE_ORGANIZATION",
      payload: createRequest.data.responseData,
    });
    console.log(createRequest.data);
  } else {
    console.log(createRequest.data);
  }
  return createRequest.data;
};

// Create Product
export const createProduct = (data) => async (dispatch) => {
  console.log(data);
  const createProductFunction = httpsCallable(functions, "createProduct");
  const createRequest = await createProductFunction(data);
  // dispatch({
  //   type: "ADD_NEW_PRODUCT_TO_STORE",
  //   payload: createRequest.data,
  // });
  return createRequest.data;
};

// Delete Asset
export const deleteAsset = (data) => async (dispatch) => {
const deleteAssetFunction = httpsCallable(functions, "deleteAsset");
  const createRequest = await deleteAssetFunction(data);
  return createRequest.data;
};

// Delete Playbook
export const deletePlaybook = (data) => async (dispatch) => {
  const deletePlaybookFunction = httpsCallable(functions, "deletePlaybook");
    const createRequest = await deletePlaybookFunction(data);
    return createRequest.data;
  };

// Delete Product
export const deleteProduct = (data) => async (dispatch) => {
  const deleteProductFunction = httpsCallable(functions, "deleteProduct");
  const createRequest = await deleteProductFunction(data);
  return createRequest.data;
};

// Delete Organization
export const deleteOrganization = (data) => async (dispatch) => {
  const deleteOrganizationFunction = httpsCallable(
    functions,
    "deleteOrganization"
  );
  const createRequest = await deleteOrganizationFunction(data);
  return createRequest.data;
};

// Get Selected Organization Data
export const selectedOrganizationData = (data) => async (dispatch) => {
  dispatch({
    type: "ADD_GRANULAR_LOADING",
    payload: ["products", "collaborators"],
  });
  const getSelectedOrganizationData = httpsCallable(
    functions,
    "getSelectedOrganizationData"
  );

  try {
    dispatch({ type: "SET_PERMISSION_IMPACTED_LOADING" });

    const createRequest = await getSelectedOrganizationData(data);
    if (createRequest.data.success) {
      dispatch({
        type: "UPDATE_ORGANIZATION",
        payload: createRequest.data.responseData,
      });
      dispatch({ type: "CLEAR_PERMISSION_IMPACTED_LOADING" });
      dispatch({
        type: "REMOVE_GRANULAR_LOADING",
        payload: ["products", "collaborators"],
      });
    } else {
      console.log(createRequest.data);
    }
  } catch (err) {
    console.log(err);
  }
};

// Get Selected Product Data
export const selectedProductData = (data) => async (dispatch) => {
  // Set the granular loader for everything that this will impact
  dispatch({
    type: "ADD_GRANULAR_LOADING",
    payload: ["playbooks", "assets", "product", "positioning"],
  });

  const getSelectedProductData = httpsCallable(
    functions,
    "getSelectedProductData"
  );
  try {
    const createRequest = await getSelectedProductData(data);
    if (createRequest.data.success) {
      dispatch({
        type: "SET_SELECTED_PRODUCT",
        payload: createRequest.data.responseData,
      });
      // Set the granular loader for everything that this will impact
      dispatch({
        type: "REMOVE_GRANULAR_LOADING",
        payload: ["playbooks", "assets", "product", "positioning"],
      });

      return createRequest.data;
    } else {
      dispatch({
        type: "SET_SELECTED_PRODUCT",
        payload: {
          message: createRequest.data.message,
          product: {
            organizationId: data.organizationId,
            id: data.productId,
            error: true,
          },
        },
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// Invite Collaborator to an organization
export const inviteCollaborator = (data) => async (dispatch) => {
  const createInviteFunction = httpsCallable(
    functions,
    "inviteUserToOrganization"
  );
  try {
    const createRequest = await createInviteFunction(data);
    if (createRequest.data.success) {
      if (createRequest.data.responseData.invitationStatus === "pending") {
        dispatch({
          type: "UPDATE_PENDING_INVITES",
          payload: {
            organizationId: createRequest.data.organizationId,
            email: createRequest.data.responseData.email,
            id: createRequest.data.responseData.docId,
          },
        });
      } else if (
        createRequest.data.responseData.invitationStatus === "existing"
      ) {
      } else if (
        createRequest.data.responseData.invitationStatus === "complete"
      ) {
        dispatch({
          type: "UPDATE_RBAC",
          payload: {
            organizationId: createRequest.data.organizationId,
            email: createRequest.data.responseData.email,
            uid: createRequest.data.responseData.docId,
            firstName: createRequest.data.responseData.firstName,
            lastName: createRequest.data.responseData.lastName,
            role: createRequest.data.responseData.role,
          },
        });
      } else {
        console.log(createRequest.data.responseData.invitationStatus);
      }
    }
    return createRequest.data;
  } catch (err) {
    console.log(err);
  }
};

// Get Pending Invites
export const pendingInviteData = (data) => async (dispatch) => {
  const getPendingInvites = httpsCallable(functions, "getPendingInvites");
  try {
    const createRequest = await getPendingInvites(data);
    if (createRequest.data.success) {
      dispatch({
        type: "SET_PENDING_INVITES",
        payload: createRequest.data,
      });
      console.log(createRequest.data);
    } else {
      console.log(createRequest.data);
    }
  } catch (err) {
    console.log(err);
  }
};

// Quick Start Positioning TODO: Add success confirmation in the component
export const quickStartPositioning = (data) => async (dispatch) => {
  const triggerQuickStartPositioning = httpsCallable(
    functions,
    "quickStartPositioning"
  );

  console.log(data);
  const createRequest = await triggerQuickStartPositioning(data);

  if (createRequest.data.success) {
    console.log("success");
    console.log(createRequest.data);
  } else {
    console.log("fail");
    console.log(createRequest.data);
  }
  return createRequest.data;
};

// DEV: Trigger Action
export const devAction = (data) => async (dispatch) => {
  const developmentFunction = httpsCallable(functions, "developmentFunction");
  try {
    const createRequest = await developmentFunction(data);
    console.log("DEV TRIGGER RESPONSE: ", createRequest);
  } catch (err) {
    console.log(err);
  }
};

// DEV: OpenAI Playground Function
export const openAIPlaygroundAction = (data) => async (dispatch) => {
  const openAIPlaygroundFunction = httpsCallable(
    functions,
    "openAIPlaygroundFunction"
  );
  try {
    const createRequest = await openAIPlaygroundFunction(data);
    return createRequest.data;
  } catch (err) {
    return err;
  }
};

// Human Loop Feedback
export const humanLoopFeedback = (data) => async (dispatch) => {
  const humanLoopFeedbackFunction = httpsCallable(
    functions,
    "humanLoopFeedback"
  );
  try {
    const createRequest = await humanLoopFeedbackFunction(data);
    return createRequest.data;
  } catch (err) {
    console.log(err);
  }
};
